.outer-padding {
    padding: 75px 0;
}

.container {
    margin-bottom: 130px;
}

.container-48 {
    margin-bottom: 48px;
}
