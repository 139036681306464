.items-container {
  margin-top: 70px;
}

.items-container__item {
  position: relative;
  margin-right: 30px;

  h4 {
    display: block;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.8rem;
    min-height: 56px;
    margin-bottom: 20px;
  }

  p {
    color: #000;
    line-height: 2.4rem;
  }
}

.items-container__img-block {
  height: 142px;
}

.icon-service-container {
  background-color: #e7f5ff;
  border-radius: 10px;
  display: block;
  height: 128px;
  width: 128px;

  .icon-service {
    margin: 0 auto;
    display: block;
    padding-top: 28px;
  }

  .video-service {
    width: 130px;
  }
}

.icon-check {
  position: absolute;
  top: 90px;
  left: 90px;
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .items-container__item {
    margin-top: 55px;
  }
}
