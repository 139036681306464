@import '../../assets/styles/colors';

.banner,
.page-container {
  background: $pink;
  box-sizing: border-box;
  max-width: 1360px;
  margin: 0 auto;
  padding: 62px 0;
  padding-left: 95px;
  width: 100%;
  h1.main-title {
    font-size: 5.4rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 6.2rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 2rem;
    font-weight: 400;
    line-height: 3.2rem;
    margin-top: 15px;
    margin-bottom: 40px;
    max-width: 90%;
  }
}

.text-info {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: 15px;
  // max-width: 95%;
  width: 270px;
}

.banner-img {
  margin-right: -5px;
}

/*
    Media Queries
*/

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .banner {
    overflow: hidden;
    padding-left: 20px;

    h1.main-title {
      font-size: 3.2rem;
      line-height: 3.9rem;
      margin-bottom: 20px;
    }
  }
  .banner-img {
    margin-top: 55px;
  }
}
