.two-cols-container {
  margin-bottom: 15rem;
}

.two-cols-container--margin-bottom {
  margin-bottom: 10rem;
}

.check-list {
  margin: 0;
  padding: 0;
  li {
    background: url('../../assets/icons/icon-check-lg.svg') no-repeat 1% 10%;
    background-size: 24px 24px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.8rem;
    list-style: none;
    padding-left: 52px;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      font-weight: 400;
      font-size: 1.6rem;
      margin: 0;
    }
  }
}

img.has-top-margin-25 {
  margin-top: 25px;
}

/*

    Variations
*/

.two-cols--list-only {
  li {
    padding: 10px 0 10px 52px;
    background-position: 0;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .two-cols-container {
    margin-bottom: 10rem;
  }

  .flex-two-cols .left-col {
    margin-right: 0;
  }
}
