// styles for terms and conditions, privacy and FAQ

.container-document {
  max-width: 65%;
  margin: 50px auto;

  hr {
    border-top: 1px solid #2a225233;
  }

  h2 {
    margin-top: 30px;
    font-size: 30px;
    &.center {
      text-align: center;
    }

    &.min {
      font-size: 20px;
    }

    &.faq-title {
      color: #2a2252dd;
      font-size: 20px;
    }
  }

  ul {
    margin: 2em 0;
  }

  p {
    margin: 0.75em 0;

    &.faq-p {
      font-size: 16px;
    }
  }

  .mt-2 {
    margin-top: 2em;
  }

  .mt-3 {
    margin-top: 3em;
  }
}

// fb fix position
.fb_dialog_content {
  > iframe {
    bottom: 40px !important;
    right: 20px !important;
  }
}
