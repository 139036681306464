.section-with-action-wrapper {
    padding: 150px 100px;
    text-align: center;

    .section-title {
        margin-bottom: 20px;
    }

    .section-sub {
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
    .section-with-action-wrapper {
        padding: 150px 20px;
    }
}
