@import '../../assets/styles/colors';

.block-with-form {
    .flex-item {
        flex: 1 1 0px;
    }

    h2 {
        margin-bottom: 10px;
    }

    .sub-text {
        font-size: 2rem;
        font-weight: 500;
        line-height: 3.2rem;
        margin-top: 0;
        margin-bottom: 65px;
    }

    .social-area {
        margin-bottom: 45px;
    }

    .span-info {
        color: #aab3c6;
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 2rem;
        margin-bottom: 15px;
        &.email-info {
            margin-bottom: 0;
        }
    }

    .contact-email {
        color: $purple;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 3.2rem;
        text-decoration: none;
    }
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
    .block-with-form {
        .left-col {
            margin-bottom: 30px;
        }
    }
}
