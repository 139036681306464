@import './assets/styles/colors';

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body,
textarea {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2252;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper-inner {
  margin: 0 auto;
  max-width: 1175px;
}

h2.subtitle {
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 32px;
}

h2.section-header {
  font-size: 4.4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 5.4rem;
  margin-top: 0;
  max-width: 70%;
}

.section-title {
  font-size: 4.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 59px;
  margin-bottom: 30px;
  margin-top: 0;
  &.section-title--20-bottom {
    margin-bottom: 20px;
  }
}

.wrapper-rounded {
  border-radius: 16px;
}

.outer-lg {
  margin: 0 auto;
  max-width: 1700px;
}

.outer-pink {
  background: $pink;
}

.outer-purple {
  background: $purple;
}

.outer-white {
  background: #fff;
  margin-bottom: 60px;
}

.outer--gray {
  background: $gray;
}

.bg-blue {
  background: #edf6fe;
}

.flex-container {
  display: flex;
  &.flex-align {
    align-items: center;
  }
}

.flex-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-end {
  justify-content: flex-end;
}

.flex-col {
  flex-basis: 100%;
  margin-right: 20px;
}

/*
    Form styling 
*/

form {
  input {
    border-radius: 6px;
  }

  .field-error {
    border: 1px solid #f4282d !important;
  }
  .field-error-label {
    color: #f52d33;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
  }
}

.hide {
  display: none;
}

.hide-mobile {
  display: none !important;
}

.only-show-mobile {
  display: none !important;
}

// Media queries

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .two-cols-container--with-img .section-title {
    margin-top: 45px;
  }
  body .container-mobile {
    padding-right: 20px;
    padding-left: 20px;
  }

  .hide-desktop {
    display: none;
  }

  .only-show-desktop {
    display: none !important;
  }

  .hide-mobile {
    display: block !important;
  }

  .only-show-mobile {
    display: block !important;
  }

  .flex-container.flex-mobile--col {
    flex-direction: column;
  }

  .flex-container.flex-cols-reverse {
    flex-direction: column-reverse;
  }

  .banner h1.main-title,
  .section-title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  h2.section-header {
    max-width: 100%;
  }

  .righ-col-img {
    margin-top: 50px;
  }
}
