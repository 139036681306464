@import '../../assets/styles/colors';

nav {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;

  a {
    color: $purple;
    text-decoration: none;
  }

  .logo-container {
    position: relative;
    // Beta label
    span {
      position: absolute;
      top: -8px;
      right: -3px;
      background-color: $magenta;
      color: white;
      font-weight: bold;
      padding: 0 5px;
      border-radius: 4px;
      font-size: 1.2rem;
    }
  }

  .img-logo,
  .img-logo-beta {
    margin-top: 5px;
  }
}

.nav-inner {
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 0;
}

nav ul {
  margin-left: 66px;
  padding: 0;

  li {
    display: inline-block;
    font-size: 1.5rem;
    list-style: none;
    padding-right: 60px;
  }
}

.button-area {
  margin-left: auto;

  .color-white {
    color: #fff;
  }

  .btn:first-of-type {
    margin-right: 30px;
  }
}

/*
    Mobile menu
*/

.hamburger-menu,
.mobile-menu {
  display: none;
}

.hamburger-menu {
  cursor: pointer;
  float: right;
  height: 35px;
  margin: 20px;
  margin-top: 35px;
  position: relative;
  z-index: 1;
  width: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $purple;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburger-menu span:nth-child(1) {
  top: 0px;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 8px;
}

.hamburger-menu span:nth-child(4) {
  top: 16px;
}

// Menu opened

.is-mobile-menu {
  .hamburger-menu span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .hamburger-menu span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .hamburger-menu span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .hamburger-menu span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

/*
    Media Queries
*/

@media only screen and (min-width: 375px) and (max-width: 812px) {
  nav {
    padding-right: 20px;
    padding-left: 20px;
  }
  .img-logo-beta {
    width: 60px;
  }

  .hamburger-menu {
    display: block;
  }

  .button-area {
    display: none;
  }

  .nav-menu {
    display: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 45px;
      padding-right: 0;
    }
  }

  .wrapper.is-mobile-menu {
    .nav-menu {
      align-items: center;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-y: auto;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: 0.5s;
    }
    li a {
      position: relative;
      text-decoration: none;
      font-size: 3rem;
      font-weight: 700;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
  }
}
