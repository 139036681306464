.outer-padding--variant {
  padding-top: 40px;
  padding-bottom: 40px;
  .two-cols-container {
    margin-bottom: 0;
  }
}

.top-offset {
  margin-top: -230px;
}

.two-cols-container--with-img {
  .left-col {
    p {
      font-size: 2rem;
      line-height: 2.8rem;
      &:first-of-type {
        margin-bottom: 3rem;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .two-cols-container--with-img {
    margin-top: 200px;
  }

  .top-offset {
    width: 100%;
  }
}
