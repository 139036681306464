.form-migration-mobile {
    input[name='software'] {
        border: none;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 1.6rem;
        height: 49px;
        line-height: 2rem;
        padding-left: 20px;
        width: 100%;
    }

    button.btn {
        margin-top: 45px;
        width: 100%;
    }

    .form-field-mobile {
        width: 100%;
    }
}
