@import '../assets/styles/colors';

.btn {
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding: 14px 0;
  padding-bottom: 15px;
  text-align: center;
  transition: 0.4s;
}

.btn:hover {
  opacity: 1;
}

.btn-md {
  width: 170px;
}

.btn-lg {
  font-size: 2rem;
  font-weight: bold;
  line-height: 24px;
  padding: 17px 0;
  width: 270px;
}

.btn-full {
  width: 100%;
}

.btn-transparent {
  background: transparent;
  border: 1px solid $purple;
  color: $purple;

  &:hover {
    background: $purple;
    color: #fff;
  }
}

.btn-purple {
  background: $purple;
  border: 1px solid $purple;
  color: #fff;
}

.btn-magenta {
  background: $magenta;
  border: 1px solid $magenta;
  color: #fff;
}

.btn-invalid {
  cursor: none;
  opacity: 0.3;
}
