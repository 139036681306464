@import '../../assets/styles/colors';

footer.footer {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1175px;
    padding: 85px 0;
    padding-right: 110px;

    .flex-container {
        justify-content: space-between;
    }
    .footer-list a {
        color: $purple;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: 400;
        text-decoration: none;
    }

    .geo-info {
        margin-right: 20px;
        .footer-geo,
        .footer-language {
            background-size: 20px 20px;
            margin-bottom: 20px;
            padding-left: 30px;
        }

        .footer-geo {
            background: url('../../assets//icons/icon-geo.svg') no-repeat;
        }

        .footer-language {
            background: url('../../assets//icons//icon-chat.svg') no-repeat;
            background-position: 0px 10px;
            display: inline-block;
            width: 125px;
        }
    }

    .geo-info .footer-geo,
    h5 {
        display: block;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
    }

    h5 {
        margin-top: 0;
        margin-bottom: 12px;
    }

    ul {
        padding: 0;
        margin: 0;
        li {
            font-size: 1.4rem;
            line-height: 3rem;
            list-style-type: none;
            margin-bottom: 0;
        }
    }

    .last-item {
        margin-right: 20px;
    }

    .footer-social {
        margin-top: 20px;
    }

    .footer-address {
        font-weight: bold;
        span {
            display: block;
        }
    }
}

// Language dropdown

.footer-lang--dropdown .Dropdown-control {
    border: none;
}

.footer-lang--dropdown .Dropdown-arrow {
    border-color: $purple transparent transparent;
}
.footer-lang--dropdown.is-open .Dropdown-arrow {
    border-color: transparent transparent $purple;
}

/*
    Media Queries
*/

@media only screen and (min-width: 375px) and (max-width: 812px) {
    footer.footer {
        margin: 80px 20px;
        padding: 0;
        .footer-list-container {
            margin-top: 40px;
            width: 50%;
        }

        .last-item {
            margin-right: 0;
        }

        .footer-language {
            width: 80%;
        }

        .geo-info {
            margin: 0;
            width: 100%;

            span.footer-geo,
            span.footer-language {
                background-position: left;
                padding-left: 30px;
            }
        }
    }
}
