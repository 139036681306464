.sm-card {
  background: #fff;
  border: 1px solid #2a2252;
  border-radius: 8px;
  padding: 20px;

  &.purple-bg {
    background: #2a2252;
    color: #fff;

    button {
      background: #fff;
      color: #2a2252;
    }
  }
}

.sm-card--body {
  min-height: 60px;
}

.sm-card--title {
  font-size: 32px;
  margin-top: 0;
}

.sm-card--sub {
  font-weight: bold;
}

.text--big {
  font-size: 28px;
  font-weight: bold;
}

.sm-card--link {
  text-decoration: none;

  &:visited,
  &:active {
    color: inherit;
  }
}
