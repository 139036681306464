@import '../../assets/styles/colors';

.form-contact,
.form-registration {
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .form-field {
    margin-right: 30px;
    margin-bottom: 30px;

    &.form-field-dropdown {
      width: 270px;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    label {
      display: block;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 14px;
    }

    input,
    textarea {
      border: 1px solid #eaeaf3;
      box-sizing: border-box;
      font-size: 1.6rem;
      line-height: 2rem;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      width: 270px;
    }
  }
  .form-field__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
  }

  #message_textarea {
    height: 169px;
    padding: 0;
    padding: 20px 0 0 20px;
    padding-bottom: 100px;
    padding-left: 20px;
    width: 570px;
  }

  .error-validation {
    font-size: 1.1rem;
    display: block;
    max-width: 100%;
    display: block;
    max-width: 270px;
    margin-top: 10px;
    color: red;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.oval-circle-red {
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    text-indent: -9999px;
    background-color: #e10000;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 16px;
  }
}

.oval--left {
  float: left;
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
  .form-contact,
  .form-registration {
    .flex-container {
      flex-direction: column;
    }

    .form-field {
      width: 100%;
      &.form-field-dropdown {
        width: 100%;
      }
      input,
      #message_textarea {
        width: 100%;
      }
    }

    button {
      width: 100%;
    }
  }
}
