.footer-social {
    margin-top: 20px;
    padding: 0;
    li.footer__icon {
        background-size: 24px 24px;
        display: inline-block;
        height: 24px;
        margin-left: 24px;
        width: 24px;
        &:first-of-type {
            margin-left: 0;
        }

        a {
            color: transparent;
            display: block;
            height: 24px;
            width: 24px;
        }
    }
    .icon-linkedin {
        background: url('../../assets/icons/icon-linkedin.svg') no-repeat;
    }
    .icon-fb {
        background: url('../../assets/icons/icon-facebook.svg') no-repeat;
    }
    .icon-ig {
        background: url('../../assets/icons/icon-instagram.svg') no-repeat;
    }
}
