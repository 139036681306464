.col__ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    background: url('../../assets/icons/icon-check-lg.svg') no-repeat;
    background-size: 24px 24px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.8rem;
    padding-left: 35px;
    margin-bottom: 20px;
  }
}

.col__head {
  color: #2a2252;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.col__list-item {
  height: inherit !important;
}
