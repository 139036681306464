@import '../../assets/styles/colors';

.form-migration {
    .form-field {
        margin-right: 50px;
        margin-bottom: 30px;
        width: 470px;

        &.lg-label {
            label {
                width: 150px;
            }
        }

        &.xl-label {
            label {
                width: 230px;
            }
        }
    }
    .form-field__title {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
    }

    input[name='software'] {
        border: none;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 1.6rem;
        height: 49px;
        line-height: 2rem;
        padding-left: 20px;
        width: 470px;
    }
}

.form-radio-area {
    display: flex;
    &.short-btn {
        label {
            width: 86px;
        }
    }
}

.radiobtn {
    &:last-of-type {
        margin-right: 0;
    }
    position: relative;
    display: block;
    margin-right: 10px;
    label {
        background: #fff;
        color: $purple;
        border: 1px solid #eaeaf3;
        border-radius: 5px;
        cursor: pointer;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 1.3rem;
        font-weight: bold;
        // height: 50px;
        min-width: 90px;
        padding: 0 15px;
        text-align: center;
        height: 50px;
        display: table-cell;
        vertical-align: middle;
    }
    input[type='radio'] {
        display: none;
        position: absolute;
        width: 100%;
        appearance: none;
        &:checked + label {
            background: $purple;
            border-color: $purple;
            color: white;
            &:before {
                width: 20px;
                height: 20px;
            }
        }
    }
    label:hover,
    label:active,
    input:hover + label,
    input:active + label {
        border-color: $purple;
    }
}

// .radiobtn.has-alignment label {
//     padding-top: 5px;
// }

.form-migration .btn {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
}
